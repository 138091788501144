var JTProject = function(app) {
	this.app = app;

	this.init();
};

JTProject.prototype.init = function() {

};

JTProject.prototype.open = function(block,object,title) {
	var self = this;

	if(!this.app.mobile)
	{
		var clone = block.clone();

		clone.addClass("clone clone-remove");

		$("html").addClass("overflow");

		var params = {
			width	: block.find("img").width(),
			height	: block.find("img").height(),
			top 	: block.offset().top - $(window).scrollTop(),
			left 	: block.offset().left
		};

		params.scrollTop = (block.offset().top + (params.height/2)) - ($(window).height() / 2);

		var diff = params.scrollTop - $(window).scrollTop();

		var mx = 250;
		
		if(diff<mx&&diff>-mx)
		{
			if(diff<0)
			{
				diff = diff * -1;
			}

			params.scrollSpeed = this.app.mapRange(diff,0,mx,0,500);
		}
		else
		{
			params.scrollSpeed = 500;
		}


		$("html,body").animate({ scrollTop : params.scrollTop },params.scrollSpeed).promise().then(function() {

			params = {
				width	: block.find("img").width(),
				height	: block.find("img").height(),
				top 	: block.offset().top - $(window).scrollTop(),
				left 	: block.offset().left
			};

			var url = clone.find("img").attr("src");

			clone.find(".image").css({"background-image":"url("+url+")"});
			clone.find("img").remove();

			clone.width(params.width).height(params.height).css({
				"left" 	: params.left,
				"top"	: params.top
			});

			$(".project_pool").prepend(clone);
			$(".project_pool").prepend("<div class='clone-remove clone-bg'></div>");

			var cClone = clone;

			setTimeout(function() {

				var windW = $(window).width(),
					windH = $(window).height();

				var mod = 0.6;

				if(windW <= 480)
				{
					mod = 0.85;
				}
				else if(windW <= 768)
				{
					mod = 0.9;
				}

				var wid = windW * mod,
					hei = windH * mod;

				cClone.animate({
					width: wid,
					height: hei,
					left: (windW - wid) / 2,
					top: (windH - hei) / 2
				},250,'easeInCubic');

				$(".clone-bg").addClass("transition");
				
				self.app.history.pushState(object, title, object.url);
			},10);
		});
	}
	else
	{
		self.app.history.pushState(object, title, object.url);
	}
}

JTProject.prototype.close = function() {
	$(".tile.clone").remove();
	$("html").removeClass("overflow");
};
// ===================== 
//
// @function events
//
// This function binds any event handlers
//
// =====================

	JTApp.prototype.dummyImages = function() {
		var self = this;
		
		$("[data-dummy]").each(function(i,e) {

			$(this).css({ "opacity":0 });

			var original 	= $(this).data("original"),
				copy 		= $(this);

			self.loadImage(original,function() {
				copy.attr("src",original).fadeTo(500,1);
				copy.parent().addClass("ready");
			});
		});
	};


// ===================== 
//
// @function loadImage
//
// @param src 		: URL of image to load
// @param callback 	: (optional) callback function
//
// This function loads an image via javascript and then
// optionally fires a callback.
//
// =====================

	JTApp.prototype.loadImage = function(src,callback) {
		var img = new Image();

		img.src = src;

		if(typeof callback == 'function')
		{
			img.onload = callback;
		}
	};


// ===================== 
//
// @function iframeResize
//
// This cycles through the iframes on screen and 
// resizes them to the ratio of 16:9 , matching the
// width to the width of the parent element.
//
// =====================

	JTApp.prototype.iframeResize = function() {

		// $("iframe").each(function(i,e) {

		// 	var wid = $(this).parent().width(),
		// 		hei = (wid * 9) / 16;

		// 	$(this).width(wid).height(hei);

		// 	if($(this).parent().is("figure"))
		// 	{
		// 		var par = $(this).parent(),
		// 			marg = (par.height() - $(this).height())/ 2;

		// 		if(marg<0)
		// 		{
		// 			$(this).css("margin-top",0);

		// 			marg = (par.width() - $(this).width())/ 2;
		// 		}
		// 	}
		// });
	};

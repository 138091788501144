

// ===================== 
//
// @function setupHistory
//
// Binds the necessary elements to work with the
// history.js api. Pushes any state changes to the
// getContent function.
//
// =====================

	JTApp.prototype.setupHistory = function() {
		var self = this;

		this.history = window.History;

		this.history.Adapter.bind(window,'statechange',function(){
			var State = self.history.getState();

			if(State.data.url!=null)
			{
				/*
				If google analytics is setup, this can 
				push the ajax call to google analytics

				ga('send', 'pageview', {'page': State.data.slug,'title': State.data.title});
				*/

				self.getContent(State.data);
			}
			else
			{
				self.getContent(null);
			}
		});
	};


// ===================== 
//
// @function getContent
//
// @param data 		: Data that is stored in the history.js state
//
// This function takes the data from the history state change
// and makes the necessary ajax call and returns the data to be used
//
// =====================

	JTApp.prototype.getContent = function(data) {
		var self = this;

		this.transitioning = true;

		var opts = {
			id 		: data.id,
			action	: "getContent",
			nonce	: this.wpNonce,
			ajax 	: 1
		};

		if(data.project)
		{
			if(data.slug!="projects")
			{
				$.getJSON(self.ajax_link, opts, function(data)
				{
					self.transitioning = false;
					$(".project_pool").prepend(data.content);
					if(self.mobile)
					{
						$(".single_project").hide();
						$(".single_project").fadeIn(500);
					}
					$(".clone-remove").remove();
					
					self.fire();
				});
			}
			else
			{
				$("html").removeClass("overflow");
				$(".closer").fadeOut(500,function() {
					$(this).remove();
				});
				$(".single_project").removeClass("trans");

				setTimeout(function() {
					$(".single_project").fadeOut(500, function() {
						$(this).remove();
						self.transitioning = false;
					});
				},250);
			}
		}
		else
		{
			$(".ajax_content").fadeOut(500,function() {
				$.getJSON(self.ajax_link, opts, function(data)
				{
					if(data.active=="selected")
					{
						$("nav").addClass("white");
					}
					else
					{
						$("nav").removeClass("white");
					}
					
					self.transitioning = false;
					$(".ajax_content").replaceWith(data.content);
					$(".ajax_content").hide().fadeIn(500);

					$(".primary-nav a").removeClass("active");

					if(data.active)
					{
						$(".primary-nav a."+data.active).addClass("active");
					}
					self.fire();
				});
			});
		}
	};